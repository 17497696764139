<script setup>
import { Head } from "@inertiajs/vue3";
defineProps({
    canLogin: Boolean,
    canRegister: Boolean,
    laravelVersion: String,
    phpVersion: String,
    auth: Object,
});
import { Disclosure, DisclosureButton, DisclosurePanel } from "@headlessui/vue";
import { MinusSmallIcon, PlusSmallIcon } from "@heroicons/vue/24/outline";
import {
    UsersIcon,
    ChevronRightIcon,
    PuzzlePieceIcon,
    PaintBrushIcon,
    BanknotesIcon,
    CalendarDaysIcon,
    SpeakerXMarkIcon,
    CreditCardIcon,
} from "@heroicons/vue/20/solid";

import ApplicationLogoWhite from "@/Components/ApplicationLogoWhite.vue";
import LandingNav from "@/Components/LandingNav.vue";
import FooterNav from "@/Components/FooterNav.vue";
import { onMounted } from "vue";

const why_get_a_tutor = [
    {
        name: "Same course, same professors",
        description:
            "On Tutorie, you will find tutors who have taken the same course, with the same professors, as you. So you get relevant help.",
        icon: UsersIcon,
    },
    {
        name: "Personalized sessions, or group sessions",
        description:
            "Easily find a tutor who can help you in your preferred format. Whether it's a one-on-one session, or a group session, you'll find them on Tutorie.",
        icon: PaintBrushIcon,
    },
    {
        name: "Always look for the perfect match",
        description:
            "By learning your struggles, we can recommend the best tutor for you, who is most suited to help you in the classes you're behind on.",
        icon: PuzzlePieceIcon,
    },
];
const why_become_a_tutor = [
    {
        name: "Turn knowledge into income",
        description:
            "Easily earn some extra cash by helping students who are struggling in the classes you've already aced.",
        icon: BanknotesIcon,
    },
    {
        name: "On your own schedule",
        description:
            "At Tutorie, you set your own schedule. You can tutor as much, or as little, as you want. Simply set time slots when you're free to tutor someone, and tutees can book you.",
        icon: CalendarDaysIcon,
    },
    {
        name: "Over with the WhatsApp spam",
        description:
            "No more spamming WhatsApp groups to find students. On Tutorie, you'll be visible to students who need help in the classes you're good at.",
        icon: SpeakerXMarkIcon,
    },
    {
        name: "Easy payouts",
        description:
            "We partner with Stripe to provide safe and secure payments, and payouts. Every transaction is through Stripe, and payouts go straight to your bank account.",
        icon: CreditCardIcon,
    },
];

const faqs = [
    {
        question: "To be honest no one really asked any questions yet...",
        answer: "So we don't have any answers yet. So enjoy programming humor.",
    },
    {
        question: "Why do programmers prefer using dark mode?",
        answer: "Because light attracts bugs.",
    },
    {
        question: "Why did the software developer go to therapy?",
        answer: "Because he had too many unresolved issues.",
    },
    {
        question: "What's a programmer's favorite olympic sport?",
        answer: "The 100 meter sprint.",
    },
    {
        question: "Why did the software developer go broke?",
        answer: "Because he ran out of cache.",
    },
    {
        question: "How do you comfort a JavaScript bug?",
        answer: "You console it.",
    },
    {
        question: "Why did the container go to therapy?",
        answer: "Because it had too many levels of isolation.",
    },
    {
        question: "What's a Kubernetes cluster's favorite free time activity?",
        answer: "Listening to pod-casts.",
    },
    // More questions...
];


onMounted(() => {
    console.log(auth);
});
</script>

<template>
    <Head title="Landing" />
    <div class="bg-gray-900">
        <!-- Header -->
        <LandingNav :auth="auth" :can-register="canRegister" />

        <main>
            <!-- Hero section -->
            <div
                class="relative pb-16 overflow-hidden bg-gray-900 isolate pt-14 sm:pb-20"
            >
                <!-- src="blend=111827&sat=-100&exp=15&blend-mode=multiply" -->
                <img
                    src="/assets/img/couches.jpg"
                    alt=""
                    class="absolute inset-0 object-cover w-full h-full -z-10 mix-blend-multiply"
                />
                <div
                    class="absolute inset-x-0 overflow-hidden -top-40 -z-10 transform-gpu blur-3xl sm:-top-80"
                    aria-hidden="true"
                >
                    <div
                        class="relative left-[calc(50%-11rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-20 sm:left-[calc(50%-30rem)] sm:w-[72.1875rem]"
                        style="
                            clip-path: polygon(
                                74.1% 44.1%,
                                100% 61.6%,
                                97.5% 26.9%,
                                85.5% 0.1%,
                                80.7% 2%,
                                72.5% 32.5%,
                                60.2% 62.4%,
                                52.4% 68.1%,
                                47.5% 58.3%,
                                45.2% 34.5%,
                                27.5% 76.7%,
                                0.1% 64.9%,
                                17.9% 100%,
                                27.6% 76.8%,
                                76.1% 97.7%,
                                74.1% 44.1%
                            );
                        "
                    />
                </div>
                <div class="px-6 mx-auto max-w-7xl lg:px-8">
                    <div class="max-w-2xl py-32 mx-auto sm:py-48 lg:py-56">
                        <div class="hidden sm:mb-8 sm:flex sm:justify-center">
                            <div
                                class="relative flex items-center px-4 py-1 text-sm leading-6 text-gray-200 rounded-full gap-x-4 ring-1 ring-white/20 hover:ring-white/30"
                            >
                                <span class="font-semibold text-blue-400"
                                    >Launched!</span
                                >
                                <span
                                    class="w-px h-4 bg-white/20"
                                    aria-hidden="true"
                                />
                                <a
                                    :href="route('register')"
                                    class="flex items-center gap-x-1"
                                >
                                    <span
                                        class="absolute inset-0"
                                        aria-hidden="true"
                                    />
                                    Join the tutoring revolution
                                    <ChevronRightIcon
                                        class="w-5 h-5 -mr-2 text-gray-200"
                                        aria-hidden="true"
                                    />
                                </a>
                            </div>
                        </div>
                        <div class="text-center">
                            <h1
                                class="text-4xl font-medium tracking-tight text-white sm:text-6xl"
                            >
                                Tutorie helps your academics to
                                <span class="font-bold text-blue-400"
                                    >bloom</span
                                >
                            </h1>
                            <p class="mt-6 text-lg leading-8 text-gray-300">
                                Easily tutor, or find a qualified tutor, from
                                your own university.
                            </p>
                            <div
                                class="flex items-center justify-center mt-10 gap-x-6"
                            >
                                <a
                                    :href="route('register')"
                                    class="rounded-md bg-blue-500 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-blue-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-400"
                                    >Find a tutor</a
                                >
                                <a
                                    :href="route('register')"
                                    class="text-sm font-semibold leading-6 text-white"
                                    >Become a tutor
                                    <span aria-hidden="true">→</span></a
                                >
                            </div>
                        </div>
                    </div>
                </div>

                <div
                    class="absolute inset-x-0 top-[calc(100%-13rem)] -z-10 transform-gpu overflow-hidden blur-3xl sm:top-[calc(100%-30rem)]"
                    aria-hidden="true"
                >
                    <div
                        class="relative left-[calc(50%+3rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-20 sm:left-[calc(50%+36rem)] sm:w-[72.1875rem]"
                        style="
                            clip-path: polygon(
                                74.1% 44.1%,
                                100% 61.6%,
                                97.5% 26.9%,
                                85.5% 0.1%,
                                80.7% 2%,
                                72.5% 32.5%,
                                60.2% 62.4%,
                                52.4% 68.1%,
                                47.5% 58.3%,
                                45.2% 34.5%,
                                27.5% 76.7%,
                                0.1% 64.9%,
                                17.9% 100%,
                                27.6% 76.8%,
                                76.1% 97.7%,
                                74.1% 44.1%
                            );
                        "
                    />
                </div>
            </div>

            <div class="py-24 bg-white sm:py-32" id="why_get_a_tutor">
                <div class="px-6 mx-auto max-w-7xl lg:px-8">
                    <div class="max-w-2xl mx-auto lg:mx-0">
                        <h2
                            class="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl"
                        >
                            Why get a tutor?
                        </h2>
                        <p class="mt-6 text-lg leading-8 text-gray-600"></p>
                    </div>
                    <div
                        class="max-w-2xl mx-auto mt-16 sm:mt-20 lg:mt-24 lg:max-w-none"
                    >
                        <dl
                            class="grid max-w-xl grid-cols-1 gap-x-8 gap-y-16 lg:max-w-none lg:grid-cols-3"
                        >
                            <div
                                v-for="reason in why_get_a_tutor"
                                :key="reason.name"
                                class="flex flex-col"
                            >
                                <dt
                                    class="text-base font-semibold leading-7 text-gray-900"
                                >
                                    <div
                                        class="flex items-center justify-center w-10 h-10 mb-6 bg-blue-600 rounded-lg"
                                    >
                                        <component
                                            :is="reason.icon"
                                            class="w-6 h-6 text-white"
                                            aria-hidden="true"
                                        />
                                    </div>
                                    {{ reason.name }}
                                </dt>
                                <dd
                                    class="flex flex-col flex-auto mt-1 text-base leading-7 text-gray-600"
                                >
                                    <p class="flex-auto">
                                        {{ reason.description }}
                                    </p>
                                </dd>
                            </div>
                        </dl>
                    </div>
                </div>
            </div>

            <div class="py-24 bg-gray-900 sm:py-32" id="why_become_a_tutor">
                <div class="px-6 mx-auto max-w-7xl lg:px-8">
                    <div class="max-w-2xl mx-auto lg:mx-0">
                        <p
                            class="mt-2 text-3xl font-bold tracking-tight text-white sm:text-4xl"
                        >
                            Why become a tutor on Tutorie?
                        </p>
                        <p class="mt-6 text-lg leading-8 text-gray-300">
                            We're always looking for the best tutors to help
                            students. Here's why you should become a tutor on
                            our platform.
                        </p>
                    </div>
                    <dl
                        class="grid max-w-2xl grid-cols-1 gap-8 mx-auto mt-16 text-base leading-7 text-gray-300 sm:grid-cols-2 lg:mx-0 lg:max-w-none lg:gap-x-16"
                    >
                        <div
                            v-for="reason in why_become_a_tutor"
                            :key="reason.name"
                            class="relative pl-9"
                        >
                            <dt class="inline font-semibold text-white">
                                <component
                                    :is="reason.icon"
                                    class="absolute w-5 h-5 text-blue-500 left-1 top-1"
                                    aria-hidden="true"
                                />
                                {{ reason.name }}
                            </dt>
                            {{ " " }}
                            <dd class="inline">{{ reason.description }}</dd>
                        </div>
                    </dl>
                </div>
            </div>

            <!-- FAQ section -->
            <!-- <div class="px-6 mx-auto mt-32 max-w-7xl sm:mt-56 lg:px-8">
                <div class="max-w-4xl mx-auto divide-y divide-gray-900/10">
                    <h2
                        class="text-2xl font-bold leading-10 tracking-tight text-gray-900"
                    >
                        Frequently asked questions
                    </h2>
                    <dl class="mt-10 space-y-6 divide-y divide-gray-900/10">
                        <Disclosure
                            as="div"
                            v-for="faq in faqs"
                            :key="faq.question"
                            class="pt-6"
                            v-slot="{ open }"
                        >
                            <dt>
                                <DisclosureButton
                                    class="flex items-start justify-between w-full text-left text-gray-900"
                                >
                                    <span
                                        class="text-base font-semibold leading-7"
                                        >{{ faq.question }}</span
                                    >
                                    <span class="flex items-center ml-6 h-7">
                                        <PlusSmallIcon
                                            v-if="!open"
                                            class="w-6 h-6"
                                            aria-hidden="true"
                                        />
                                        <MinusSmallIcon
                                            v-else
                                            class="w-6 h-6"
                                            aria-hidden="true"
                                        />
                                    </span>
                                </DisclosureButton>
                            </dt>
                            <DisclosurePanel as="dd" class="pr-12 mt-2">
                                <p class="text-base leading-7 text-gray-600">
                                    {{ faq.answer }}
                                </p>
                            </DisclosurePanel>
                        </Disclosure>
                    </dl>
                </div>
            </div> -->
        </main>

        <!-- Footer -->
        <FooterNav />
    </div>
</template>
